.globe-wrapper {
    perspective: 1200px;
    width: 500px;
    height: 500px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.globe {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1.5s linear;
}

.globe-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85vw;
    height: auto;
    object-fit: cover;
    border-radius: 5%;
    transform-origin: center center;
    transform-style: preserve-3d;
    filter: blur(3px);
    opacity: 0;
    /* opacity: 0.1; */

    transition: transform 2s, filter 2s, opacity 0.8s;
}

.globe-image.in-focus {
    width: 85vw;
    height: auto;
    transform: translate(-50%, -50%) scale(1.2) !important;
    filter: none !important;
    opacity: 1 !important;
    z-index: 10;
}